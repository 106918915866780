import React from 'react'
import { Grid } from '@material-ui/core'
import TitleComponent from '../General/TitleComponent';
import RichTextComponent from '../General/RichTextComponent';
import VideoComponent from '../General/VideoComponent'
import SectionWrapper from '../General/SectionWrapper'

export default function OurStory({
    title = "About", secondaryTitles = [],
    description = '', videoUrl = '',
    vision = '', mission = ''
}) {
     
    return <div id='our-story'>
        <SectionWrapper sectionId='variation-1'>
            <Grid container spacing={4}>
                {/* <Grid item xs={12}>
                    <div style={{
                        paddingBottom: '1.5rem'
                    }}>
                        <TitleComponent titleAlign={'left'} title={title} secondaryTitles={secondaryTitles} />
                    </div>
                </Grid> */}
                <Grid item xs={12}>
                    <div style={{
                        marginBottom: '0.75rem'
                    }}>
                        <TitleComponent
                            title='About marketing superhero'
                            titleAlign='left'
                          />
                    </div>
                    <RichTextComponent text={description} />
                </Grid>
            </Grid>
            <Grid container spacing={4}>                
                <Grid item xs={6}>
                    <div style={{
                        marginBottom: '0.75rem'
                    }}>
                        <TitleComponent
                            title='Vision'
                            titleAlign='left'
                            titleColor='secondary'
                          />
                    </div>
                    <RichTextComponent text={vision} />
                </Grid>
                <Grid item xs={6}>
                    <div style={{
                        marginBottom: '0.75rem'
                    }}>
                        <TitleComponent
                            title='Mission'
                            titleAlign='left'
                            titleColor='secondary'
                          />
                    </div>
                    <RichTextComponent text={mission} />
                </Grid>
            </Grid>
        </SectionWrapper>
        <SectionWrapper sectionId='variation-2'>
            <Grid container alignItems='center' justifyContent='center' spacing={4}>
                <Grid item xs={12} >
                    <div style={{
                        marginTop: '2rem',
                        paddingBottom: '1.5rem'
                    }}>
                        <TitleComponent smallTitle='Our Story' title={"Watch Our Super Story & Know Our Superpowers"} secondaryTitles={["Super", 'Story']} />
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={10}>
                    <VideoComponent url={videoUrl} />
                </Grid>
            </Grid>
        </SectionWrapper>
    </div >
}