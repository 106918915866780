import React from 'react'
import { makeStyles, Typography, Grid } from '@material-ui/core'
// import { generalTextColor } from '../../utitlities/Configurations'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import SocialLinksRender from '../General/SocialLinksRender'
// import { useStaticQuery, graphql } from 'gatsby'
import PrimaryButton from '../General/Buttons/Primary'

const useStyles = makeStyles(theme => ({
    container: {
        // border: `1px solid ${theme.palette.primary.main}`,
        boxShadow: 'none',
        borderRadius: "8px",
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column'
        // '&:hover': {
        //     border: `1px solid ${theme.palette.secondary.main}`,
        //     boxShadow: "0px 9px 35px 0px rgb(0 0 0 / 9%)"
        // }
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    textContainer: {
        flex: 1,
        padding: "1rem",
        backgroundColor: "#fbf6f6",
        [theme.breakpoints.up('sm')]: {
            padding: "1.5rem"
        },
    },
    imageContainer: {
        // height: '400px',
        // [theme.breakpoints.up('sm')]: {
        //     height: "350px"
        // },
        // [theme.breakpoints.up('md')]: {
        //     height: "250px"
        // }
        height:'auto'

    },
    primaryDivider: {
        width: "30%",
        height: "5px",
        backgroundColor: theme.palette.primary.main
    }, secondaryDivider: {
        width: "30%",
        height: "5px",
        backgroundColor: theme.palette.secondary.main
    }
}))

export default function TeamCard({
    image = '', name = '', designation = '', imageUrl = '',
    socialLinks = [], showCta = false, ctaProps = {}, primaryDivider = true
}) {
    // const fallBackQuery = useStaticQuery(graphql`
    // query TeamFallBackQuery1 {
    // allFile(filter: {sourceInstanceName: {eq: "images"},name:{eq:"TeamFallBack"}}
    // ) {
    //   edges {
    //     node {
    //       childImageSharp {
    //         gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
    //       }
    //     }
    //   }
    // }
    //   }          
    // `)
    const classes = useStyles()
    return <div id={name} className={classes.container}>
        {
            (!!image || !!imageUrl) &&
            <div className={classes.imageContainer}>
                {!!image}
                <GatsbyImage image={getImage(image)} alt={name} className={classes.image} imgClassName={classes.image} />
                {!!imageUrl}
                <img src={imageUrl} alt={name} className={classes.image} imgClassName={classes.image} />
            </div>
        }
        <div className={classes.textContainer}>
            {
                showCta ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <PrimaryButton {...ctaProps} />
                    </div>
                    :
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container >
                                <Grid item xs={12}>
                                    <Typography variant='h6' component='p' style={{ fontWeight: 700, letterSpacing: '1px' }} color='primary'>{name}</Typography>
                                </Grid>
                                {
                                    !!designation &&
                                    <Grid item xs={12}>
                                        <Typography variant='body2' component='p' style={{ fontWeight: 600, textTransform: 'uppercase' }} color='secondary' >{designation}</Typography>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        {
                            socialLinks.length !== 0 &&
                            <Grid item container xs={12}>
                                <Grid item className={primaryDivider ? classes.primaryDivider : classes.secondaryDivider}></Grid>
                            </Grid>
                        }
                        {
                            socialLinks.length !== 0 &&
                            <Grid item xs={12}>
                                <SocialLinksRender list={socialLinks} buttonStyles={{ padding: "0px" }} spacing={1} />
                            </Grid>
                        }
                    </Grid>
            }

        </div>
    </div>
}