import React, {useState, useEffect} from 'react'
import { Grid } from '@material-ui/core'
import TitleComponent from '../General/TitleComponent'
import TeamCard from './TeamCard'
import SectionWrapper from '../General/SectionWrapper'
import { useStaticQuery, graphql } from 'gatsby'
import { fetchData } from '../../utitlities/helperFunctions'



export default function OurTeam({
  title = 'Meet The Superheroes At Vybinex', secondaryTitles = ['Superheros', 'Vybinex'],
}) {
  const teamsQuery = useStaticQuery(graphql`
    query TeamsQuery {
        teams: allApiAbout {
          nodes {
            teams {
              designation
              id
              name
              photo_url
              socialLinks {
                id
                url
              }
            }
          }
        }
          joinTeam:allFile(filter: {extension: {eq: "png"}, name: {eq: "JoinTeam"}}) {
            edges {
              node {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
                }
              }
            }
          }
      }          
    `)
    const [AboutData,SetData] = useState()     
    useEffect(() => { 
      fetchData(`about`)
      .then(x => SetData(x) )         
  }, []); 
  const teams = AboutData ? AboutData.teams : teamsQuery.teams.nodes[0].teams   
  const joinTeam = teamsQuery.joinTeam.edges[0].node
  return <SectionWrapper sectionId='our-team'>
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <div style={{
          paddingBottom: '3rem'
        }}>
          <TitleComponent
            title={title} secondaryTitles={secondaryTitles}
            smallTitle='Our Leaders'
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3} >
          {
            teams.map((item, index) => {               
              return <Grid key={item.id} item xs={12} sm={6} md={3}>
                <TeamCard {...item} primaryDivider={index % 2 === 0} imageUrl={item.photo_url} />
              </Grid>
            })
          }
          <Grid item xs={12} sm={6} md={3}>
            <TeamCard showCta={true} ctaProps={{
              label: "Join Team",
              buttonProps: { href: "https://www.linkedin.com/company/vybinex/", target: "_blank" }
            }} primaryDivider={(teams.length + 1) % 2 !== 0} image={joinTeam} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </SectionWrapper>
}
