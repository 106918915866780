import React, {useState, useEffect} from 'react'
import Layout from '../components/Layout'
import Banner from '../components/Banner'
import OurTeam from '../components/About/Teams'
import OurStory from '../components/About/OurStory'
import { graphql } from 'gatsby'
import { fetchData } from '../utitlities/helperFunctions'

const AboutPage = ({ data }) => {
  const [AboutData,SetData] = useState()     
  useEffect(() => { 
    fetchData(`about`)
    .then(x => SetData(x) )         
}, []);
  const aboutPageData = AboutData ? AboutData : data.about.edges[0].node
  const bannerImageData = {url: data.bannerImage.edges[0].node.childImageSharp.original.src}
  const pageSeo = aboutPageData.Seo
  return (
    <Layout pageSeo={pageSeo}>
      <Banner
        bannerId="about-banner"
        heading={aboutPageData.heading}
        subHeading={aboutPageData.subHeading}
        secondaryHeadings={aboutPageData.headingHighlights}
        imageComponent={bannerImageData}
        imageUrl={true}
        showBg={true}
      />
      <OurStory
        description={aboutPageData.story.description}
        videoUrl={aboutPageData.story.videoUrl}
        vision={aboutPageData.story.vision}
        mission={aboutPageData.story.mission}
      />
      <OurTeam />
      {/* <MessageBanner
        title="Join Us"
        subTitle="Be part of a prestigious team!"
      /> */}
    </Layout>
  )
}

export const query = graphql`
query AboutPageQuery {
    about:   allApiAbout {
      edges {
        node {
           Seo {
                description
                title
              }
              heading
              headingHighlights
              subHeading
              story {
                  description
                  videoUrl
                }
        }
      }
    }
    bannerImage: allFile(
      filter: {sourceInstanceName: {eq: "images"}, name: {eq: "AboutBanner"}}
    ) {
      edges {
        node {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
    }
  }
`

export default AboutPage
